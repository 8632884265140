import React from 'react';
import { Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Composant principal
const ActivatedPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-br from-white to-orange-50">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="bg-white rounded-2xl shadow-xl p-8 max-w-xl mx-auto">
          <div className="text-center">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <Check className="w-8 h-8 text-green-500" />
            </div>
            <h2 className="text-2xl font-semibold mb-2">Félicitation ! votre compte est activé.</h2>
            <div className="space-y-2 mb-4">
              <p className="text-zinc-600">
                Votre compte est activé. Vous pouvez maintenant vous connecter avec votre adresse e-mail et votre mot de passe.
              </p>
            </div>
          </div>

          <button
            onClick={() => navigate("/signin")}
            className="w-full bg-[#333697] text-white mt-10 px-6 py-3 rounded-full hover:bg-[#FC9723] transitions-color transition duration-300 text-sm font-medium">
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivatedPage;
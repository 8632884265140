import { useEffect, useState } from 'react';
import './Dashboard.css';
import { userApi } from '../../service/api/private/UserApi';
import { Bell, Search, Edit, Briefcase, Star, Send, Inbox, Users, X, MapPin, Languages, Clock, Globe2, Coffee } from 'lucide-react';
import { Tag } from '../../components/Tag';
import { AddressDto, MatchingCriteriasDto, UserDto, UserSettingsDto } from '../../dto/User';
import { getCompetencesSuggestions } from '../../const/Competences';
import { MultiSelect, MultiSelectItem } from '../../components/MultiSelect';
import { AutosuggestInput, AutosuggestOutput } from '../../components/Autosuggest';
import { geocodingApi } from '../../service/api/GeocodingApi';
import { contactsApi } from '../../service/api/private/ContactsApi';
import { UserLinksDto } from '../../dto/Contact';
import { MatchingDto } from '../../dto/Matching';
import { matchingApi } from '../../service/api/private/MatchingApi';
import { UserWidget } from '../../components/UserWidget';
import profile1 from '../../assets/images/profiles/profile_1.png';
import profile2 from '../../assets/images/profiles/profile_2.png';
import profile3 from '../../assets/images/profiles/profile_3.png';
import profile4 from '../../assets/images/profiles/profile_4.png';
import profile5 from '../../assets/images/profiles/profile_5.png';
import profile6 from '../../assets/images/profiles/profile_6.png';
import profile7 from '../../assets/images/profiles/profile_7.png';
import profile8 from '../../assets/images/profiles/profile_8.png';
import profile9 from '../../assets/images/profiles/profile_9.png';
import { getHobbiesSuggestions } from '../../const/Hobbies';
import { userService } from '../../service/UserService';
import { useNavigate } from 'react-router-dom';
import { getJobList, getJobsSuggestions } from '../../const/Jobs';

const ProfileEmoji: React.FC = () => {
    const emojis = [profile1, profile2, profile3, profile4, profile5, profile6, profile7, profile8, profile9];
    return (
        <img className='w-10 max-w-[none]' src={emojis[Math.floor(Math.random() * emojis.length)]} />
    );
};

interface ToolBoxItemProps {
    icon: React.ReactNode;
    text: string;
}

const ToolBoxItem: React.FC<ToolBoxItemProps> = ({ icon, text }) => (
    <div className="border rounded p-4 text-center hover:border-[#333697] hover:text-[#333697] transition-colors">
        <div className="mx-auto mb-2 text-[#333697]">{icon}</div>
        <p>{text}</p>
    </div>
);

const experiences = [
    { label: '< 1 an', min: 0, max: 1 },
    { label: '1 - 2 ans', min: 1, max: 2 },
    { label: '3 - 5 ans', min: 3, max: 5 },
    { label: '6 - 10 ans', min: 6, max: 10 },
    { label: '> 10 ans', min: 11, max: 100 }
]

const getExperienceLabel = (min: number, max: number) => {
    const exp = experiences.find(e => e.min === min && e.max === max);

    return exp?.label ?? `${max} ans`;
}

interface PersonalInfoWidgetProps {
    user?: UserDto;
    onUpdate: Function;
}

interface PersonalInfo {
    job: string;
    yearsOfExperience: number;
    address?: AddressDto;
    location: string;
    languages: string[];
    hobbies: string[];
}

export const PersonalInfoWidget: React.FC<PersonalInfoWidgetProps> = ({ user, onUpdate }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
        job: '',
        yearsOfExperience: 0,
        location: '',
        languages: [],
        hobbies: [],
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user) {
            setPersonalInfo({
                yearsOfExperience: user.yearsOfExperience,
                address: { ...user.address },
                location: user.address.label ?? '',
                languages: user.languages ? [...user.languages] : [],
                hobbies: user.hobbies ? [...user.hobbies] : [],
                job: user.job
            })
        }
    }, [user]);

    const [errors, setErrors] = useState({
        job: '',
        location: '',
        experience: '',
        languages: '',
        hobbies: ''
    });

    const handleSetLocation = (location: string) => {
        setPersonalInfo(prev => ({
            ...prev,
            location
        }));
    };

    const handleSetAddress = (address: AddressDto) => {
        setPersonalInfo(prev => ({
            ...prev,
            address
        }));
    };

    const handleSetHobbies = (value: MultiSelectItem[]) => {
        setPersonalInfo(prev => ({
            ...prev,
            hobbies: value.map(v => v.value)
        }));
    };

    const handleSetJob = (job: string) => {
        setPersonalInfo(prev => ({
            ...prev,
            job
        }));
    };

    const getCitiesSuggestions = async (city: string): Promise<AutosuggestOutput[]> => {
        const result = await geocodingApi.suggestCities(city);
        return result.map(address => ({ id: address.id!, label: address.label, value: address }));
    }

    const validateForm = () => {
        const newErrors = {
            job: '',
            location: '',
            experience: '',
            languages: '',
            hobbies: ''
        };
        let isValid = true;

        if (!personalInfo.job.trim()) {
            newErrors.job = 'Le métier principal est requise';
            isValid = false;
        }
        if (!personalInfo.location.trim()) {
            newErrors.location = 'La localisation est requise';
            isValid = false;
        }
        if (!personalInfo.address || personalInfo.location !== personalInfo.address.label) {
            newErrors.location = 'La localisation est inconnue';
            isValid = false;
        }

        if (!personalInfo.hobbies || personalInfo.hobbies.length === 0) {
            newErrors.languages = 'Au moins une hobbie est requise';
            isValid = false;
        }

        if (!personalInfo.languages || personalInfo.languages.length === 0) {
            newErrors.languages = 'Au moins une langue est requise';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleUpdatePersonalInfo = async () => {
        if (!validateForm()) return;

        setLoading(true);

        const updatedUser = {
            ...user,
            job: personalInfo.job,
            yearsOfExperience: personalInfo.yearsOfExperience,
            address: personalInfo.address,
            languages: personalInfo.languages,
            hobbies: personalInfo.hobbies
        } as UserDto;

        await userService.updateUser(updatedUser);
        onUpdate(personalInfo);
        setIsEditModalOpen(false);
        setLoading(false);
    };

    const handleCancel = () => {
        setIsEditModalOpen(false);
        if (user) {
            setPersonalInfo({
                job: user.job,
                yearsOfExperience: user.yearsOfExperience,
                address: { ...user.address },
                location: user.address.label ?? '',
                languages: user.languages ? [...user.languages] : [],
                hobbies: user.hobbies ? [...user.hobbies] : [],
            })
        }
    }

    return <>
        <div className="bg-white p-4 md:p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold text-base md:text-lg">Informations personnelles</h2>
                <button
                    disabled={!user}
                    className="text-[#333697] hover:bg-[#333697]/10 rounded-full p-1"
                    onClick={() => setIsEditModalOpen(true)}
                >
                    <Edit size={18} />
                </button>
            </div>
            <div className="p-6">
                {user ?
                    <div className="space-y-4">
                        <div className="flex items-center text-sm">
                            <Briefcase className="text-[#333697] w-5 h-5 mr-3 min-w-max" />
                            <div>
                                <span className="text-gray-500">Expériences :</span>
                                <span className="ml-2 font-medium">{user?.yearsOfExperience} an(s)</span>
                            </div>
                        </div>
                        <div className="flex items-center text-sm">
                            <Globe2 className="text-[#333697] w-5 h-5 mr-3 min-w-max" />
                            <div>
                                <span className="text-gray-500">Localisation :</span>
                                <span className="ml-2 font-medium">{user?.address?.label}</span>
                            </div>
                        </div>
                        <div className="flex items-start text-sm">
                            <Languages className="text-[#333697] w-5 h-5 mr-3 mt-1 min-w-max" />
                            <div>
                                <span className="text-gray-500">Langues :</span>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {user?.languages && user?.languages.map((lang, idx) => (
                                        <Tag key={idx} text={lang} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-start text-sm">
                            <Coffee className="text-[#333697] w-5 h-5 mr-3 mt-1 min-w-max" />
                            <div>
                                <span className="text-gray-500">Hobbies :</span>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {user?.hobbies && user?.hobbies.map((hobby, idx) => (
                                        <Tag key={idx} text={hobby} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                    </>
                }
            </div>
        </div>

        {/* Edit info perso Modal - Responsive */}
        {isEditModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-40 z-50 p-4 flex items-start md:items-center justify-center">
                <div className="bg-white rounded-lg shadow-xl w-full max-w-[500px] max-h-[90vh] mt-16 md:mt-0">
                    <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center z-50">
                        <h2 className="text-lg font-bold">Modification informations personnelles</h2>
                        <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
                            <X size={24} />
                        </button>
                    </div>

                    <div className="p-4">
                        <form className="space-y-6">

                            <div>
                                {/* Localisation */}
                                <label className="block mb-2 text-sm font-medium flex items-center mt-6">
                                    <Briefcase size={20} className="mr-2 min-w-max" />
                                    Métier principal :
                                </label>
                                <AutosuggestInput
                                    handleSetLabel={handleSetJob}
                                    handleSetValue={handleSetJob}
                                    defaultLabel={personalInfo?.job}
                                    className={`w-full border-2 rounded-lg p-[10px] focus:ring-2 focus:ring-pleeso-orange outline-none
                                        ${errors.job ? 'border-red-500' : 'border-[#333697]'}`}
                                    getSuggestions={getJobsSuggestions}
                                    minLength={2}
                                />
                                {errors.job && (
                                    <p className="mt-2 text-red-500 text-sm">{errors.job}</p>
                                )}

                                <label className="block mb-2 text-sm font-normal flex items-center pt-5">
                                    Années d'expérience :
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    placeholder="-- ans"
                                    value={personalInfo?.yearsOfExperience}
                                    className="w-full border-2 rounded-lg p-[10px] focus:ring-2 focus:ring-pleeso-orange outline-none border-[#333697]"
                                    onChange={(e) => setPersonalInfo(prev => ({
                                        ...prev,
                                        yearsOfExperience: parseInt(e.target.value)
                                    }))}
                                />

                                {/* Localisation */}
                                <label className="block mb-2 text-sm font-medium flex items-center mt-6">
                                    <MapPin size={20} className="mr-2" />
                                    Localisation :
                                </label>
                                <AutosuggestInput
                                    handleSetLabel={handleSetLocation}
                                    handleSetValue={handleSetAddress}
                                    defaultLabel={personalInfo?.location}
                                    placeholder="Laval, France"
                                    className={`w-full border-2 rounded-lg p-[10px] focus:ring-2 focus:ring-pleeso-orange outline-none
                                        ${errors.location ? 'border-red-500' : 'border-[#333697]'}`}
                                    getSuggestions={getCitiesSuggestions}
                                />
                                {errors.location && (
                                    <p className="mt-2 text-red-500 text-sm">{errors.location}</p>
                                )}

                                {/* Langues */}
                                <div className='mt-6'>
                                    <label className="block mb-2 text-sm font-medium flex items-center">
                                        <Languages size={20} className="mr-2 min-w-max" />
                                        Langues :
                                    </label>
                                    <div className="flex flex-wrap gap-2">
                                        {['Français', 'Anglais', 'Espagnol', 'Allemand'].map((language) => (
                                            <button
                                                key={language}
                                                type="button"
                                                className={`bg-orange-100 text-orange-800 px-2 py-1 rounded-full text-xs ${personalInfo?.languages && personalInfo?.languages.indexOf(language) >= 0 ? "border-2 border-orange-800" : ""}`}
                                                onClick={() => setPersonalInfo(prev => {
                                                    let languages = prev.languages;
                                                    if (languages.includes(language)) {
                                                        languages = languages.filter(l => l !== language);
                                                    } else {
                                                        languages.push(language);
                                                    }
                                                    return {
                                                        ...prev,
                                                        languages
                                                    };
                                                })}
                                            >
                                                {language}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                {errors.languages && (
                                    <p className="mt-2 text-red-500 text-sm">{errors.languages}</p>
                                )}

                                {/* Hobbies */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium flex items-center mt-6">
                                        <Coffee size={20} className="mr-2 min-w-max" />
                                        Hobbies :
                                    </label>
                                    <MultiSelect
                                        values={personalInfo.hobbies.map((c, id) => ({ id: c, value: c }))}
                                        items={getHobbiesSuggestions}
                                        placeholder="Ajouter un loisir"
                                        handleSetValue={handleSetHobbies}

                                        className={`flex border-2 rounded-lg p-[10px] relative
                                        ${errors.hobbies ? 'border-red-500' : 'border-[#333697]'}`}
                                    />
                                    {errors.hobbies && (
                                        <p className="mt-2 text-red-500 text-sm">{errors.hobbies}</p>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="sticky bottom-0 bg-white p-4 border-t flex flex-col sm:flex-row gap-2 sm:justify-end">
                        <button
                            type="button"
                            onClick={handleCancel}
                            disabled={loading}
                            className="w-full sm:w-auto px-4 py-2 border border-[#333697] text-[#333697] rounded hover:bg-[#333697]/10 transition-colors text-sm"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="w-full sm:w-auto px-4 py-2 bg-[#333697] text-white rounded hover:bg-[#333697]/90 transition-colors text-sm"
                            onClick={handleUpdatePersonalInfo}
                            disabled={loading}
                        >
                            Modifier
                        </button>
                    </div>
                </div>
            </div>
        )}

    </>
};

interface MatchingCriteriasWidgetProps {
    matchingCriterias?: MatchingCriteriasDto;
    onUpdate: Function;
}

interface MatchingConfigs {
    fieldsOfActivities: string[];
    location: string;
    address: AddressDto | undefined;
    distanceInKm: number;
    yearsOfExperienceMin: number;
    yearsOfExperienceMax: number;
    languages: string[];
}

export const MatchingCriteriasWidget: React.FC<MatchingCriteriasWidgetProps> = ({ matchingCriterias, onUpdate }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [matchingConfigs, setMatchingConfigs] = useState<MatchingConfigs>({
        fieldsOfActivities: [],
        location: '',
        address: undefined,
        distanceInKm: 0,
        yearsOfExperienceMin: 0,
        yearsOfExperienceMax: 0,
        languages: []
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (matchingCriterias) {
            setMatchingConfigs({
                fieldsOfActivities: matchingCriterias?.fieldsOfActivities ?? [],
                location: matchingCriterias?.address.label ?? '',
                address: matchingCriterias?.address,
                distanceInKm: matchingCriterias?.distanceInKm ?? 0,
                yearsOfExperienceMin: matchingCriterias.yearsOfExperienceMin ?? 0,
                yearsOfExperienceMax: matchingCriterias.yearsOfExperienceMax ?? 0,
                languages: matchingCriterias.languages ?? []
            })
        }
    }, [matchingCriterias]);

    const [errors, setErrors] = useState({
        competences: '',
        location: '',
        experience: '',
        languages: ''
    });

    const handleSetValue = (value: MultiSelectItem[]) => {
        setMatchingConfigs(prev => ({
            ...prev,
            fieldsOfActivities: value.map(v => v.value)
        }));
    };


    const handleSetLocation = (location: string) => {
        setMatchingConfigs(prev => ({
            ...prev,
            location
        }));
    };

    const handleSetAddress = (address: AddressDto) => {
        setMatchingConfigs(prev => ({
            ...prev,
            address
        }));
    };

    const getCitiesSuggestions = async (city: string): Promise<AutosuggestOutput[]> => {
        const result = await geocodingApi.suggestCities(city);
        return result.map(address => ({ id: address.id!, label: address.label, value: address }));
    }

    const validateForm = () => {
        const newErrors = {
            competences: '',
            location: '',
            experience: '',
            languages: ''
        };
        let isValid = true;

        if (!matchingConfigs.fieldsOfActivities || matchingConfigs.fieldsOfActivities.length === 0) {
            newErrors.competences = 'Au minimum une compétence est requise';
            isValid = false;
        }

        if (!matchingConfigs.location.trim()) {
            newErrors.location = 'La localisation est requise';
            isValid = false;
        }
        if (!matchingConfigs.address || matchingConfigs.location !== matchingConfigs.address.label) {
            newErrors.location = 'La localisation est inconnue';
            isValid = false;
        }

        if (matchingConfigs.yearsOfExperienceMax === 0 && matchingConfigs.yearsOfExperienceMin === 0) {
            newErrors.experience = 'Expérience est requise';
            isValid = false;
        }

        if (!matchingConfigs.languages || matchingConfigs.languages.length === 0) {
            newErrors.experience = 'Langue est requise';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleUpdateMatchingConfigs = async () => {
        if (!validateForm()) return;

        setLoading(true);
        const newMatchingCriterias = {
            ...matchingCriterias!,
            fieldsOfActivities: matchingConfigs.fieldsOfActivities,
            address: matchingConfigs.address!,
            distanceInKm: matchingConfigs.distanceInKm,
            yearsOfExperienceMin: matchingConfigs.yearsOfExperienceMin,
            yearsOfExperienceMax: matchingConfigs.yearsOfExperienceMax,
            languages: matchingConfigs.languages

        };

        await userApi.setMatchingCriterias(newMatchingCriterias);
        setIsEditModalOpen(false);
        onUpdate(newMatchingCriterias);
        setLoading(false);
    };

    const handleCancel = () => {
        setIsEditModalOpen(false);
        if (matchingCriterias) {
            setMatchingConfigs({
                fieldsOfActivities: matchingCriterias?.fieldsOfActivities ?? [],
                location: matchingCriterias?.address.label ?? '',
                address: matchingCriterias?.address,
                distanceInKm: matchingCriterias?.distanceInKm ?? 0,
                yearsOfExperienceMin: matchingCriterias.yearsOfExperienceMin ?? 0,
                yearsOfExperienceMax: matchingCriterias.yearsOfExperienceMax ?? 0,
                languages: matchingCriterias.languages ?? []
            })
        }
    }

    return <>
        <div className="bg-white p-4 md:p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold text-base md:text-lg">Critères de recherches</h2>
                <button
                    disabled={!matchingCriterias}
                    className="text-[#333697] hover:bg-[#333697]/10 rounded-full p-1"
                    onClick={() => setIsEditModalOpen(true)}
                >
                    <Edit size={18} />
                </button>
            </div>
            {matchingCriterias ?
                <div className="space-y-3 md:space-y-4">
                    <div>
                        <p className="font-medium text-sm mb-2">Localisation :</p>
                        <div className="flex flex-wrap gap-2">
                            <Tag text={matchingCriterias.address.label} />
                        </div>
                    </div>
                    <div>
                        <p className="font-medium text-sm mb-2">Compétences recherchées :</p>
                        <div className="flex flex-wrap gap-2">
                            {
                                matchingCriterias.fieldsOfActivities?.map(f => (<Tag key={f} text={f} />))
                            }
                        </div>
                    </div>
                    <div>
                        <p className="font-medium text-sm mb-2">Années d'expérience :</p>
                        <div className="flex flex-wrap gap-2">
                            {matchingCriterias.yearsOfExperienceMin && matchingCriterias.yearsOfExperienceMax &&
                                <Tag text={getExperienceLabel(matchingCriterias.yearsOfExperienceMin, matchingCriterias.yearsOfExperienceMax)} />
                            }
                        </div>
                    </div>
                    <div>
                        <p className="font-medium text-sm mb-2">Langues :</p>
                        <div className="flex flex-wrap gap-2">
                            {
                                matchingCriterias.languages?.map(l => (<Tag key={l} text={l} />))
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="p-6">
                    <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                    <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                    <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                    <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                </div>
            }
        </div>

        {/* Edit Criteria Modal - Responsive */}
        {isEditModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40 p-4 flex items-start md:items-center justify-center">
                <div className="bg-white rounded-lg shadow-xl w-full max-w-[500px] max-h-[90vh] mt-16 md:mt-0">
                    <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center z-50">
                        <h2 className="text-lg font-bold">Modification des critères</h2>
                        <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
                            <X size={24} />
                        </button>
                    </div>

                    <div className="p-4">
                        <form className="space-y-6">
                            {/* Compétences */}
                            <div>
                                <label className="block mb-2 text-sm font-medium flex items-center">
                                    <Search size={20} className="mr-2" />
                                    Compétences recherchées :
                                </label>
                                <MultiSelect
                                    values={matchingConfigs.fieldsOfActivities.map((c, id) => ({ id: c, value: c }))}
                                    items={getJobList}
                                    placeholder="Ajouter une compétence"
                                    handleSetValue={handleSetValue}

                                    className={`flex border-2 rounded-lg p-[10px] relative
                                        ${errors.competences ? 'border-red-500' : 'border-[#333697]'}`}
                                />
                                {errors.competences && (
                                    <p className="mt-2 text-red-500 text-sm">{errors.competences}</p>
                                )}
                                {/* <div className="mt-2">
                                    <p className="text-xs text-gray-600 mb-2">Suggestions:</p>
                                    <div className="flex flex-wrap gap-2">
                                        {['Banque d\'investissement', 'Gestion de patrimoine', 'Services financiers', 'Analyse de crédit'].map((skill) => (
                                            <span key={skill} className="bg-[#333697]/10 text-[#333697] px-2 py-1 rounded-full text-xs">
                                                {skill}
                                            </span>
                                        ))}
                                    </div>
                                </div> */}
                            </div>

                            {/* Localisation */}
                            <div>
                                <label className="block mb-2 text-sm font-medium flex items-center">
                                    <MapPin size={20} className="mr-2" />
                                    Localisation :
                                </label>
                                <label className="block mb-2 text-sm font-normal flex items-center">
                                    Ville
                                </label>
                                <AutosuggestInput
                                    handleSetLabel={handleSetLocation}
                                    handleSetValue={handleSetAddress}
                                    defaultLabel={matchingConfigs.location}
                                    placeholder="Laval, France"
                                    className={`w-full border-2 rounded-lg p-[10px] focus:ring-2 focus:ring-pleeso-orange outline-none
                                        ${errors.location ? 'border-red-500' : 'border-[#333697]'}`}
                                    getSuggestions={getCitiesSuggestions}
                                />
                                {errors.location && (
                                    <p className="mt-2 text-red-500 text-sm">{errors.location}</p>
                                )}
                                <label className="block mb-2 text-sm font-normal flex items-center pt-5">
                                    Dans un rayon de {matchingConfigs.distanceInKm}km
                                </label>
                                <input
                                    type="range"
                                    min="0"
                                    max="200"
                                    value={matchingConfigs.distanceInKm}
                                    className="w-full accent-[#333697]"
                                    onChange={(e: any) => setMatchingConfigs(prev => ({
                                        ...prev,
                                        distanceInKm: parseInt(e.target.value)
                                    }))}
                                />
                                <div className="flex justify-between text-xs text-gray-600">
                                    <span>0km</span>
                                    <span>200km</span>
                                </div>
                                {/* <div className="mt-2">
                                    <p className="text-xs text-gray-600 mb-2">Suggestions:</p>
                                    <div className="flex flex-wrap gap-2">
                                        {['Paris', 'Bordeaux', 'Lyon', 'Marseille'].map((location) => (
                                            <span key={location} className="bg-orange-100 text-orange-800 px-2 py-1 rounded-full text-xs">
                                                {location}, France
                                            </span>
                                        ))}
                                    </div>
                                </div> */}
                            </div>

                            {/* Années d'expérience */}
                            <div>
                                <label className="block mb-2 text-sm font-medium flex items-center">
                                    <Clock size={20} className="mr-2" />
                                    Années d'expérience :
                                </label>
                                <div className="flex flex-wrap gap-2">
                                    {experiences.map((experience) => (
                                        <button
                                            key={experience.label}
                                            type="button"
                                            className={`bg-orange-100 text-orange-800 px-2 py-1 rounded-full text-xs ${matchingConfigs.yearsOfExperienceMax === experience.max && matchingConfigs.yearsOfExperienceMin === experience.min ? "border-2 border-orange-800" : ""}`}

                                            onClick={() => setMatchingConfigs(prev => ({
                                                ...prev,
                                                yearsOfExperienceMax: experience.max,
                                                yearsOfExperienceMin: experience.min
                                            }))}
                                        >
                                            {experience.label}
                                        </button>
                                    ))}
                                    <input
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder="-- ans"
                                        value={matchingConfigs.yearsOfExperienceMax === matchingConfigs.yearsOfExperienceMin ? matchingConfigs.yearsOfExperienceMax : ''}
                                        className={`border rounded px-2 py-1 text-xs w-16 ${matchingConfigs.yearsOfExperienceMax === matchingConfigs.yearsOfExperienceMin ? "border-2 border-orange-800" : ""}`}
                                        onChange={(e) => setMatchingConfigs(prev => ({
                                            ...prev,
                                            yearsOfExperienceMax: parseInt(e.target.value),
                                            yearsOfExperienceMin: parseInt(e.target.value)
                                        }))}
                                    />
                                </div>
                            </div>

                            {/* Langues */}
                            <div>
                                <label className="block mb-2 text-sm font-medium flex items-center">
                                    <Languages size={20} className="mr-2 min-w-max" />
                                    Langues :
                                </label>
                                <div className="flex flex-wrap gap-2">
                                    {['Français', 'Anglais', 'Espagnol', 'Allemand'].map((language) => (
                                        <button
                                            key={language}
                                            type="button"
                                            className={`bg-orange-100 text-orange-800 px-2 py-1 rounded-full text-xs ${matchingConfigs.languages.indexOf(language) >= 0 ? "border-2 border-orange-800" : ""}`}
                                            onClick={() => setMatchingConfigs(prev => {
                                                let languages = prev.languages;
                                                if (languages.includes(language)) {
                                                    languages = languages.filter(l => l !== language);
                                                } else {
                                                    languages.push(language);
                                                }
                                                return {
                                                    ...prev,
                                                    languages
                                                };
                                            })}
                                        >
                                            {language}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="sticky bottom-0 bg-white p-4 border-t flex flex-col sm:flex-row gap-2 sm:justify-end">
                        <button
                            type="button"
                            onClick={handleCancel}
                            disabled={loading}
                            className="w-full sm:w-auto px-4 py-2 border border-[#333697] text-[#333697] rounded hover:bg-[#333697]/10 transition-colors text-sm"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="w-full sm:w-auto px-4 py-2 bg-[#333697] text-white rounded hover:bg-[#333697]/90 transition-colors text-sm"
                            onClick={handleUpdateMatchingConfigs}
                            disabled={loading}
                        >
                            Modifier
                        </button>
                    </div>
                </div>
            </div>
        )}

    </>
};

export const Dashboard: React.FC = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [user, setUser] = useState<UserDto>();
    const [userlinks, setUserlinks] = useState<UserLinksDto>();
    const [matching, setMatching] = useState<MatchingDto>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            userApi.getCurrentUser()
                .then((result) => setUser(result))
                .catch((error) => console.error(error));

            contactsApi.getCurrentUserLinks()
                .then((result) => setUserlinks(result))
                .catch((error) => console.error(error));

            matchingApi.getMatching({ size: 1, page: 0 })
                .then((result) => setMatching(result))
                .catch((error) => console.error(error));
        }
    }, [user]);

    const getContactedMatchCount = () => {
        return userlinks?.contacts.filter(c => !c.contacted).length;
    }

    const handleUpdateMatchingConfigs = async (newMatchingCriterias: MatchingCriteriasDto) => {

        const userSettings: UserSettingsDto = { ...user!.userSettings!, matchingCriterias: newMatchingCriterias };
        setUser({ ...user!, userSettings });

        setMatching(undefined);
        setMatching(await matchingApi.getMatching({ size: 1, page: 0 }))
    }

    const handleUpdatePersonalInfo = async (personalInfo: PersonalInfo) => {
        setUser({
            ...user!,
            job: personalInfo.job,
            yearsOfExperience: personalInfo.yearsOfExperience!,
            address: { ...personalInfo.address! },
            languages: [...personalInfo.languages!],
            hobbies: [...personalInfo.hobbies!]
        });
    }

    return (
        <>
            <div className="flex-1 px-4 py-4 md:p-6 lg:p-8 overflow-y-auto h-[100%]">
                {/* Dashboard Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6">
                    {/* User Profile */}
                    <UserWidget user={user}>
                        {/* <div className="bg-[#B3B4E5] p-2 rounded text-center text-xs md:text-sm text-gray-600">
                            Cofondateur non-trouvé
                        </div> */}
                    </UserWidget>

                    {/* Updates */}
                    <div className="bg-orange-50 p-4 md:p-6 rounded-lg shadow md:col-span-2">
                        <h2 className="font-bold text-base md:text-lg mb-2 flex items-center">
                            <Bell className="mr-2 text-orange-500" size={20} />
                            Nouveautés
                        </h2>
                        <p className="text-xs md:text-sm text-gray-600">
                            Découvrez notre dernière mise à jour : une fonctionnalité de gestion des
                            ressources révolutionnaire qui optimise l'allocation des talents et
                            maximise l'efficacité de vos projets...
                        </p>
                    </div>

                    {/* Search Criteria */}
                    <PersonalInfoWidget user={user} onUpdate={handleUpdatePersonalInfo} />

                    {/* Search Criteria */}
                    <MatchingCriteriasWidget matchingCriterias={user?.userSettings?.matchingCriterias} onUpdate={handleUpdateMatchingConfigs} />

                    <div className="bg-white p-4 md:p-6 rounded-lg shadow">
                        {/* Found Co-founders */}
                        <div className="px-4 md:px-6">
                            <div className="flex items-center mb-3 md:mb-4">
                                <div className="bg-[#333697]/10 p-2 rounded-full mr-3">
                                    <Briefcase className="text-[#333697] min-w-max" size={20} />
                                </div>
                                {matching ?
                                    <h2 className="font-bold text-base md:text-lg">{matching.totalElements} co-fondateurs trouvés</h2>
                                    :
                                    <h2 className="bg-gray-400 animate-pulse h-4 w-3/4 mb-2"></h2>
                                }
                            </div>
                            <div className="grid grid-cols-4 md:grid-cols-6 gap-2 mb-4">
                                {[...Array(Math.min(12, matching?.totalElements ?? 12))].map((_, i) => (
                                    <ProfileEmoji key={i} />
                                ))}
                            </div>
                            <button
                                onClick={() => navigate('/dashboard/matching')}
                                className="w-full bg-white text-[#333697] border border-[#333697] px-4 py-2 rounded text-sm hover:bg-[#B3B4E5] hover:text-bg transition-colors"
                            >
                                Voir les profils
                            </button>
                        </div>

                        {/* Saved Profiles */}
                        <div className="px-4 md:px-6 pt-4 md:pt-6">
                            <div className="flex items-center mb-3 md:mb-4">
                                <div className="bg-[#333697]/10 p-2 rounded-full mr-3">
                                    <Star className="text-[#333697]" size={20} />
                                </div>
                                {userlinks ?
                                    <h2 className="font-bold text-base md:text-lg">{userlinks?.favoriteUsers.length ?? 0} profils sauvegardés</h2>
                                    :
                                    <h2 className="bg-gray-400 animate-pulse h-4 w-3/4 mb-2"></h2>
                                }

                            </div>
                            <div className="grid grid-cols-4 md:grid-cols-6 gap-2 mb-4">
                                {[...Array(Math.min(12, userlinks?.favoriteUsers.length ?? 12))].map((_, i) => (
                                    <ProfileEmoji key={i} />
                                ))}
                            </div>
                            <button
                                onClick={() => navigate('/dashboard/network', { state: { view: 'FAVORITE' } })}
                                className="w-full bg-white text-[#333697] border border-[#333697] px-4 py-2 rounded text-sm hover:bg-[#B3B4E5] hover:text-bg transition-colors"
                            >
                                Consulter les profils
                            </button>
                        </div>
                    </div>
                    {/* Requests History */}
                    <div className="bg-white p-4 md:p-6 rounded-lg shadow">
                        <h2 className="font-bold text-base md:text-lg mb-4">Historique des demandes</h2>
                        <ul className="space-y-4">
                            <li>
                                <button onClick={() => navigate('/dashboard/network', { state: { view: 'REQUESTED' } })} className="flex items-center space-x-4 text-gray-700 hover:text-[#333697] w-full text-left text-sm">
                                    <Send size={18} className="text-[#333697]" />
                                    <span>Invitations envoyées</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => navigate('/dashboard/network', { state: { view: 'RECEIVED' } })} className="flex items-center space-x-4 text-gray-700 hover:text-[#333697] w-full text-left text-sm">
                                    <Inbox size={18} className="text-[#333697]" />
                                    <span>Invitations reçues</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => navigate('/dashboard/network', { state: { view: 'CONTACTS' } })} className="flex items-center space-x-4 text-gray-700 hover:text-[#333697] w-full text-left text-sm">
                                    <Users size={18} className="text-[#333697]" />
                                    <span>Mes contacts</span>
                                </button>
                            </li>
                        </ul>
                    </div>

                    {/* Matches */}
                    <div className="bg-white p-4 md:p-6 rounded-lg shadow md:col-span-2">
                        <div className="flex items-center space-x-2 mb-4">
                            <div className="flex -space-x-2">
                                {[...Array(Math.min(5, getContactedMatchCount() ?? 5))].map((_, i) => (
                                    <ProfileEmoji key={i} />
                                ))}
                            </div>
                            {userlinks ?
                                <h2 className="font-bold text-sm md:text-base text-[#333697]">
                                    Vous avez {getContactedMatchCount()} match(s) de potentiel(s) cofondateurs que vous n'avez pas encore rencontré(s).
                                </h2>
                                :
                                <h2 className="bg-gray-400 animate-pulse h-4 w-3/4 mb-2"></h2>
                            }

                        </div>
                        <p className="text-xs md:text-sm text-gray-600 mb-4">
                            Affinez vos préférences et accélérez vos chances de trouver votre co-fondateur pour
                            donner vie à votre projet.
                        </p>
                        <button onClick={() => navigate('/dashboard/network', { state: { view: 'CONTACTS' } })}
                            className="w-full bg-[#333697] text-white px-4 py-2 rounded text-sm hover:bg-[#333697]/90 transition-colors">
                            Contacter vos matchs
                        </button>
                    </div>

                    {/* Tools Section */}
                    <div className="bg-white p-4 md:p-6 rounded-lg shadow col-span-full">
                        <h2 className="font-bold text-base md:text-lg mb-4">Boîte outils PLEESO</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
                            <ToolBoxItem
                                icon={<svg className="w-5 h-5 md:w-6 md:h-6 mx-auto" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7z" />
                                </svg>}
                                text="Brainstorming"
                            />
                            <ToolBoxItem
                                icon={<svg className="w-5 h-5 md:w-6 md:h-6 mx-auto" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
                                </svg>}
                                text="Gestion de projet"
                            />
                            <ToolBoxItem
                                icon={<svg className="w-5 h-5 md:w-6 md:h-6 mx-auto" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
                                </svg>}
                                text="Développement"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}
        </>
    );
};
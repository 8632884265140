import React, { createContext, useContext, useState, useCallback } from 'react';
import { AddressDto } from '../../dto/User';

// Types
export interface OnboardingData {
  profileType?: string;
  hasProject?: boolean;
  profession?: {
    fieldOfActivity: string;
    title: string;
    experience: number;
  };
  personalInfo?: {
    firstName: string;
    lastName: string;
    birthDate: string;
    location: string;
    address?: AddressDto;
  };
  isTechnical?: boolean;
  credentials?: {
    email: string;
    password: string;
  };
};

export interface OnboardingContextType {
  data: OnboardingData;
  updateData: (newData: Partial<OnboardingData>) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

// Initial state
const initialOnboardingData: OnboardingData = {
  profileType: undefined,
  hasProject: undefined,
  profession: undefined,
  personalInfo: undefined,
  isTechnical: undefined,
  credentials: undefined
};

// Create context
export const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

// Provider Component
export const OnboardingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<OnboardingData>(initialOnboardingData);
  const [currentStep, setCurrentStep] = useState(1);

  const updateData = useCallback((newData: Partial<OnboardingData>) => {
    setData(prev => ({
      ...prev,
      ...newData
    }));
  }, []);

  const value = {
    data,
    updateData,
    currentStep,
    setCurrentStep
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

// Custom hook
export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within OnboardingProvider');
  }
  return context;
};

// Utility functions
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string): {
  isValid: boolean;
  errors: string[];
} => {
  const errors: string[] = [];

  if (password.length < 8) {
    errors.push('Au moins 8 caractères');
  }
  if (!/[A-Z]/.test(password)) {
    errors.push('Au moins une majuscule');
  }
  if (!/[a-z]/.test(password)) {
    errors.push('Au moins une minuscule');
  }
  if (!/[0-9]/.test(password)) {
    errors.push('Au moins un chiffre');
  }
  if (!/[!@#$%^&*]/.test(password)) {
    errors.push('Au moins un caractère spécial (!@#$%^&*)');
  }

  return {
    isValid: errors.length === 0,
    errors
  };
};

export const validateAge = (birthDate: string): {
  isValid: boolean;
  error: string | undefined;
} => {
  let error = undefined;

  if (!birthDate) {
    error = 'La date de naissance est requise';
  } else {
    const month_diff = Date.now() - new Date(birthDate).getTime();
    const year = new Date(month_diff).getUTCFullYear();
    const age = Math.abs(year - 1970);

    if (age < 16 || month_diff < 0) {
      error = 'Vous devez avoir au moins 16 ans';
    }
  }

  return {
    isValid: !error,
    error
  };
};

// Custom hook for password strength
export const usePasswordStrength = (password: string) => {
  const [strength, setStrength] = useState(0);
  const [feedback, setFeedback] = useState<string[]>([]);

  React.useEffect(() => {
    if (password) {
      const checks = [
        password.length >= 8,
        /[A-Z]/.test(password),
        /[a-z]/.test(password),
        /[0-9]/.test(password),
        /[!@#$%^&*]/.test(password)
      ];

      const currentStrength = checks.filter(Boolean).length;
      setStrength((currentStrength / checks.length) * 100);

      const newFeedback = [];
      if (!checks[0]) newFeedback.push('Au moins 8 caractères');
      if (!checks[1]) newFeedback.push('Au moins une majuscule');
      if (!checks[2]) newFeedback.push('Au moins une minuscule');
      if (!checks[3]) newFeedback.push('Au moins un chiffre');
      if (!checks[4]) newFeedback.push('Au moins un caractère spécial');

      setFeedback(newFeedback);
    }
  }, [password]);

  return {
    strength,
    feedback,
    isStrong: strength >= 80
  };
};

// export default OnboardingContext;
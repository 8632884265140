import { UserDto } from "../dto/User";
import { OnboardingData } from "../pages/signup/OnboardingContext";
import { userApi } from "./api/private/UserApi";
import { authenticationApi } from "./api/public/AuthenticationApi";
import { createUserApi } from "./api/public/CreateUserApi";
class UserService {

    public createUser = async (onboardingData: OnboardingData) => {

        let address;
        if (onboardingData.personalInfo!.address) {
            address = onboardingData.personalInfo!.address;
        } else {
            address = { label: onboardingData.personalInfo!.location };
        }

        const userDto: UserDto = {
            email: onboardingData.credentials!.email,
            firstName: onboardingData.personalInfo!.firstName,
            lastName: onboardingData.personalInfo!.lastName,
            birthDay: new Date(onboardingData.personalInfo!.birthDate),
            address,

            job: onboardingData.profession!.title,
            profileType: onboardingData.profileType!,
            technicalProfile: onboardingData.isTechnical!,
            fieldOfActivity: onboardingData.profession!.fieldOfActivity,
            withIdea: onboardingData.hasProject ? "YES" : "NO",
            yearsOfExperience: onboardingData.profession!.experience,
            projects: [],
            userSettings: {
                downRateIgnoredMatchesUntilDays: 2,
                matchingCriterias: {
                    distanceInKm: 0,
                    address
                }
            }
        }

        const userId = await createUserApi.createUserDb(userDto);
        authenticationApi.signup({
            email: onboardingData.credentials!.email,
            password: onboardingData.credentials!.password,
            id: userId
        })
        createUserApi.createUserEs(userId);
    }

    public updateUser = async (user: UserDto) => {
        await userApi.updateUser(user);
        createUserApi.createUserEs(user.id!);
    }
}

export const userService = new UserService();
import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './Autosuggest.css';


interface AutosuggestInputProps {
    handleSetValue: (value: any) => void;
    handleSetLabel: (label: string) => void;
    className?: string;
    placeholder?: string
    defaultLabel?: string;
    getSuggestions: (value: string) => Promise<AutosuggestOutput[]>;
    minLength?: number;
}

export interface AutosuggestOutput {
    id: string;
    label: string;
    value: any;
}

export const AutosuggestInput: React.FC<AutosuggestInputProps> = ({ handleSetValue, handleSetLabel, className, placeholder, defaultLabel = '', getSuggestions, minLength = 3 }) => {
    const [suggestions, setSuggestions] = useState<AutosuggestOutput[]>([]);
    const [lastFetch, setLastFetch] = useState(new Date());

    return (
        <div>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionsFetchRequested={async ({ value: v }) => {
                    const now = new Date();
                    const delayInSec = (now.getTime() - lastFetch.getTime()) / 1000;
                    if (v.length >= minLength) {
                        setSuggestions(await getSuggestions(v));
                        setLastFetch(now);
                    }

                }}
                onSuggestionSelected={(_, { suggestion, suggestionValue, }) => {
                    handleSetValue(suggestion.value);
                }
                }
                getSuggestionValue={suggestion => suggestion.label}
                renderSuggestion={suggestion => <span>{suggestion.label}</span>}
                inputProps={{
                    placeholder,
                    value: defaultLabel,
                    onChange: (_, { newValue, method }) => {
                        defaultLabel = newValue;
                        handleSetLabel(newValue);
                    },
                    className
                }}
            />
        </div>
    );
};